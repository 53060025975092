import { url } from "./apiBase";

const adminUrl = url + 'admin/'

const AdminRoutes = {
    Branches:
    {
        GetAll: adminUrl + 'branch',
    },
    Orders:
    {
        GetAll: adminUrl + 'orders/all', //complete with {branch_id}/{type}
        GetDataToStore: adminUrl + 'orders/tostore',
        create: adminUrl + 'orders/store',
        GetOrder: adminUrl + 'orders/show',
        update: adminUrl + 'orders/update',
    },
    Entities:
    {
        GetAll: adminUrl + 'entities/all/', //Complete with {branch_id}/{type}
        Create: adminUrl + 'entities/store',
    },
    Expenses:
    {
        GetAll: adminUrl + 'expenses/all', //complete with the branch_id
        GetDataToStore: adminUrl + 'expenses/tostore',
        CreateExpenses: adminUrl + 'expenses/store',
        DeleteExpense: adminUrl + 'expenses/delete/', //complete with the expense id
    },
    Categories:
    {
        GetAll: adminUrl + 'categories/all/', //complete with {branch_id}
        Create: adminUrl + 'categories/store',
        Show: adminUrl + 'categories/show/', //completar con el id
        Update: adminUrl + 'categories/update',
    },
    Products:
    {
        GetAll: adminUrl + 'products/all/', //complete with {branch_id}
        GetDataToStore: adminUrl + 'products/tostore',
        Create: adminUrl + 'products/store',
        Update: adminUrl + 'products/update',
        Details: adminUrl + 'products/details',
    }
};


export default AdminRoutes;