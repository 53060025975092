import { useState } from "react";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import AuxAlert from "../../../components/alert";
import { UpdateDatosContacto } from "../../../controllers/company/CompanyProfileController";

export default function Contactos(props)
{
    const [phone,setPhone] = useState(props.phone);
    const [errorPhone,setErrorPhone] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] =useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);

    const formVerification = () => {
        var ban = 0;

        if(phone === '')
        {
            setErrorPhone(true)
            ban = 1;
        }
        
        return ban;
    }

    const handleUpdateDatosContacto = async () => {
        if(formVerification() === 0)
        {
            setIsLoading(true);
            let response = await UpdateDatosContacto(phone);
            if(response.success === true)
            {
                setSuccess(true);
                setTitle('Operación Exitosa!');
                setMessage(response.message);
                setOpen(true);
                await setTimeout( () => 
                    setOpen(false),7000
                );
            }
            else
            {
                setSuccess(false);
                setTitle('Operación Fallida!');
                setMessage(response.message);
                setOpen(true);
            }
            setIsLoading(false);
        }
    }

    return(
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography variant='h6' sx={{fontWeight:'bold'}}>Datos de contacto</Typography>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={() => handleUpdateDatosContacto() }
                    >
                        Actualizar
                    </LoadingButton>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        label='Correo electrónico'
                        value={props.email}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label='Teléfono'
                        value={phone}
                        onChange = { (e) => setPhone(e.target.value) }
                        onBlur={ () => (phone === '' ? setErrorPhone(true) : setErrorPhone(false) ) }
                        error = {errorPhone}
                        helperText={errorPhone && 'Debes ingresar el numero de telefono'}
                    />
                </Grid>
            </Grid>
            <AuxAlert
                open={open}
                success={success}
                title={title}
                message={message}
            />
        </Paper>
    )
}