import moment from 'moment';
import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST, POST_JSON } from "../ConsumeApiController";


export async function getAllExpenses(branch_id,start_date,end_date)
{
    let response = await POST_JSON(
        AdminRoutes.Expenses.GetAll,
        {
            branch_id,
            start_date,
            end_date
        }
    );
    return response;
}

export async function getDataToStore(branch_id)
{
    const params = [
        {name:'branch_id',value:branch_id}
    ];

    let response = await POST(AdminRoutes.Expenses.GetDataToStore,params);
    return response;
}

export async function createExpenses(branch_id,expenses)
{
    let details = expenses.map(item=>{
        let _date = moment(item.date).format('YYYY-MM-DD');
        item.date = _date;
        return item; 
    });
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'expenses',value:JSON.stringify(details)},
    ];

    let response = await POST(AdminRoutes.Expenses.CreateExpenses,params);
    return response;
}


export async function deleteExpense(id)
{
    let response = await GET(AdminRoutes.Expenses.DeleteExpense+id);
    return response;
}