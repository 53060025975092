import { useMemo, useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ProductDetailsDataTable(props)
{
    const [details,setDetails] = useState(props.details);
    const memoizedDetails = useMemo(() => props.details, [props.details]);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const columns = [
        {
            name:'date',
            label:<Typography>Fecha</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'matadero',
            label:<Typography>Matadero</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Matadero</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].matadero}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'ff',
            label:<Typography>FF</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>FF</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].ff}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'type',
            label:<Typography>Tipo</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name:'balance',
            label:<Typography>Saldo</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Saldo</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedDetails[dataIndex].balance}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'',
            label:<Typography>Detalles</Typography>,
            options:{
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'left'}>
                            #{memoizedDetails[dataIndex].order_number} - {memoizedDetails[dataIndex].notes}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title={<Typography>Detalles de {details[0].product}</Typography>}
            data={memoizedDetails}
            columns={columns}
            options={options}
        />
    )
}