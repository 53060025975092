import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Box, Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faFileInvoice, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';

//export default class TablaProductos extends Component
export default function OrdersDataTable(props)
{
    const {userData,setUserData} = useContext(UserContext)
    const [orders,setOrders] = useState(props.orders);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const columns = [
        {
            name:'orderID',
            label: <Typography>#</Typography>,
            options:{
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:true
            }
        },
        {
            name : '',
            label : <Typography>Cliente</Typography>,
            options:{
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box>
                            <Typography fontWeight={'bold'}>
                                {orders[dataIndex].cliente}
                            </Typography>
                            <Typography variant='body2'>
                                {orders[dataIndex].created_at}
                            </Typography>
                        </Box>
                    );
                },
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:true
            }
        },
        {
            name : 'codigo',
            label : <Typography>Código</Typography>,
            
            options:{
                filter:false
            }
        },
        
        {
            name : 'tipo_pedido',
            label : <Typography>Tipo Pedido</Typography>,
            options:{
                filter:true,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'tipo_entrega',
            label : <Typography>Tipo Entrega</Typography>,
            options:{
                filter:true,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'delivery',
            label : <Typography>Delivery</Typography>,
            options:{
                filter:true,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'monto',
            label : <Typography>Monto (USD)</Typography>,
            options:{
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {orders[dataIndex].monto}
                        </Typography>
                    );
                },
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:false
            }
        },
        {
            name : 'estatus',
            label : <Typography>Estatus</Typography>,
            options : 
            {
                filter: true,            
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Tooltip title='Detalles'>
                                <IconButton onClick={()=>redirect('/orders/details/'+orders[dataIndex].codigo)}>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={<Typography>Listado de Pedidos</Typography>}
            data={orders}
            columns={columns}
            options={options}
        />
    )
}