import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PasswordIcon from '@mui/icons-material/Password';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DevicesIcon from '@mui/icons-material/Devices';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WalletIcon from '@mui/icons-material/Wallet';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

import { Box, Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';





export default function SecurityListItems(){

    const { signOut } = useContext(AuthContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <div>
            <ListItem button onClick={()=>redireccionar('/security/changepassword')}>
                <ListItemIcon>
                    <PasswordIcon sx={{color:'primary.contrastText'}}/>
                </ListItemIcon>
                <ListItemText primary="Seguridad" />
            </ListItem>

            <ListItem button onClick={()=>handleSignOut()}>
                <ListItemIcon>
                    <ExitToAppIcon sx={{color:'primary.contrastText'}}/>
                </ListItemIcon>
                <ListItemText primary="Salir" />
            </ListItem>
        </div>
    )    
}
    