import AuthRoutes from '../apiRoutes/AuthRoutes';
import { GET, POST } from './ConsumeApiController';

/**
 * Function to register a person user type
 * @param email 
 * @param username 
 * @param password
 */
export async function personSignUp(name,lastName,email,password)
{
    const params = [
        {name:'name',value:name},
        {name:'lastName',value:lastName},
        {name:'email',value:email},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.personSignUp,params);
    if(response.success === true || response.success === false)
        return response;
    else
        return 0;
}


/* Function to register a person user type
 * @param email 
 * @param username 
 * @param password
 */
export async function companySignUp(name,lastName,documentType,documentNumber,category,description,city,email,password)
{
    const params = [
        {name:'',value:''},
        {name:'',value:''},
        {name:'name',value:name},
        {name:'lastName',value:lastName},
        {name:'documentType',value:documentType},
        {name:'documentNumber',value:documentNumber},
        {name:'category',value:category},
        {name:'description',value:description},
        {name:'city',value:city},
        {name:'email',value:email},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.companySingUp,params);
    if(response.success === true || response.success === false)
        return response;
    else
        return 0;
}


export async function emailVerification(email,code)
{
    const ecode = email+'/'+code;
    let response = await GET(AuthRoutes.verify+ecode);
    return response;
}


export async function login(email,password)
{
    const params = [
        {name:'email',value:email},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.signIn,params);
    console.log('LOGIN RESPONSE ===> ',response);
    return response;

}

export async function login2(email,name,provider,uid)
{
    const params = [
        {name:'email',value:email},
        {name:'name',value:name},
        {name:'provider',value:provider},
        {name:'uid',value:uid},
    ];

    let response = await POST(AuthRoutes.signIn2,params);
    return response;
}


export async function changePasswordLogin(password,confirm_password)
{
    const params = [
        {name:'password',value:password},
        {name:'confirm_password',value:confirm_password}
    ];

    let response = await POST(AuthRoutes.changePasswordLogin,params);
    return response;
}


/************************************************************************
 |----------------------------------------------------------------------|
 | Functions to reset password flow                                     |
 |----------------------------------------------------------------------| 
 */

/**
  * Function to change password
  * @param currentPassword is the current password,
  * @param password is the new password
  */
export async function changePasswordWithToken(currentPassword,password)
{
    let params = [
        {name:'currentPassword',value:currentPassword},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.changePasswordWithToken,params);
    return response;
}



/**
 * Function to request code to reset the password
 * @param email the user email
 */
export async function requestCodeToResetPassword(email)
{
   const params = [
       {name:'email',value:email}
   ];

   let response = await POST(AuthRoutes.requestCodeToResetPassword,params);
   return response;
}

/**
* Function to verify email and the confirmation code to reset the password
* @param email
* @param confirmation_code
* 
* @return @true returns true if the combination of email and confirmation_code exist
* 
*/
export async function verifyRequestedCode(email,confirmation_code)
{
   const params = [
       {name:'email',value:email},
       {name:'confirmation_code',value:confirmation_code}
   ];

   let response = await POST(AuthRoutes.verifyRequestedCode,params);
   return response;
}


export async function changePassword(email,password,confirmation_code)
{
   const params = [
       {name:'email',value:email},
       {name:'password',value:password},
       {name:'confirmation_code',value:confirmation_code}
   ];

   let response = await POST(AuthRoutes.changePassword,params);
   return response
}