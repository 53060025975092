import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StoreIcon from '@mui/icons-material/Store';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CategoryIcon from '@mui/icons-material/Category';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faGear, faGears, faGrip, faPeopleGroup, faRightFromBracket, faStore, faTruckFast } from '@fortawesome/free-solid-svg-icons';





export default function MainListItems(){

    const theme = useTheme();
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.replace(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <FontAwesomeIcon color={theme.palette.primary.contrastText} size='' icon={faGrip}/>
                <ListItemText sx={{ml:2}} primary="Inicio" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/') }>
                <FontAwesomeIcon color={theme.palette.primary.contrastText} size='' icon={faStore}/>
                <ListItemText sx={{ml:2}} primary="Sucursales" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/') }>
                <FontAwesomeIcon color={theme.palette.primary.contrastText} size='' icon={faPeopleGroup}/>
                <ListItemText sx={{ml:2}} primary="Personas" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/') }>
                <FontAwesomeIcon color={theme.palette.primary.contrastText} size='' icon={faTruckFast}/>
                <ListItemText sx={{ml:2}} primary="Pedidos" />
            </ListItem>
            
            <ListItem button onClick={() => redireccionar('/') }>
                <FontAwesomeIcon color={theme.palette.primary.contrastText} size='' icon={faGear}/>
                <ListItemText sx={{ml:2}} primary="Configuración" />
            </ListItem>
        </div>
    )    
}
    