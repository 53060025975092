import { useMemo, useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { deleteExpense } from "../../../controllers/admin/ExpensesController";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";

export default function ExpensesDataTable(props)
{
    const { type } = useParams();
    const [expenses,setExpenses] = useState(props.expenses);
    const memoizedExpenses = useMemo(() => props.expenses, [props.expenses]);

    const [isLoading,setIsLoading] = useState(false);
    const [title,setTittle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(false);
    const [open,setOpen] = useState(false);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const handleDeleteExpense = async (_id) => {
        setIsLoading(true);
        let response = await deleteExpense(_id);
        if(response.success === true)
        {
            setExpenses(expenses.filter(expense=>expense.id !== _id))
            await props.onDelete();

            setTittle('Operación Exitosa')
            setMessage(response.message)
            setSuccess(true);
            setOpen(true);
        }
        else
        {
            setTittle('Operación Fallida')
            setMessage(response.message)
            setSuccess(false);
            setOpen(true);
        }
        setIsLoading(false);
    }

    const columns = [
        {
            name:'id',
            label:<Typography>ID</Typography>,
        },
        {
            name:'date',
            label:<Typography>Fecha</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'expense_type',
            label:<Typography>Tipo de Gasto</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'description',
            label:<Typography>Descripción</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name:'entity',
            label:<Typography>Persona</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name : 'amount',
            label : 'Monto',
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Monto</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {memoizedExpenses[dataIndex].amount}
                        </Typography>
                    );
                },
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:false,
                headerStyle: {
                    textAlign: 'right' // Align header text to the right
                  }
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Eliminar'>
                                <IconButton onClick={()=>handleDeleteExpense(memoizedExpenses[dataIndex].id)}>
                                    <FontAwesomeIcon color="red" icon={faTrashAlt}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <>
            <MUIDataTable
                title={<Typography>Listado de Gastos</Typography>}
                data={memoizedExpenses}
                columns={columns}
                options={options}
            />
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={'Finalizar'}
                onClick={()=>setOpen(false)}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />
        </>
    )
}