import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';

import ClientHome from '../views/client';
import ClientLayout from '../layouts/DashboardCliente/clientLayout';
import ClientProfile from '../views/client/profile';
import ForgotPassword from '../views/auth/forgotPassword';

import Privacy from '../views/legal/privacy';
import TermsAndConditions from '../views/legal/terms';
import DeleteUser from '../views/legal/deleteUser';
import { WebSiteLayout } from '../layouts/Website/WebSiteLayout';

import ClientOrders from '../views/client/orders';



export default function ClientNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={ClientLayout} component={ClientHome} />

            {/**Legal */}
            <AppRoute exact path='/privacy' layout={ClientLayout} component={Privacy} />
            <AppRoute exact path='/termsandconditions' layout={ClientLayout} component={TermsAndConditions} />
            <AppRoute exact path='/deleteUser' layout={ClientLayout} component={DeleteUser} />



            <AppRoute exact path='/dashboard' layout={ClientLayout} component={ClientHome} />
            <AppRoute exact path='/profile' layout={ClientLayout} component={ClientProfile} />
            <AppRoute exact path='/orders/:tipo_pedido' layout={ClientLayout} component={ClientOrders} />

            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}
