import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST, POST_JSON } from "../ConsumeApiController";



export async function getAllOrders(branch_id,type,start_date,end_date)
{
    //let response = await GET(AdminRoutes.Orders.GetAll+`${branch_id}/${type}`);
    let response = await POST_JSON(
        AdminRoutes.Orders.GetAll,
        {
            branch_id,
            type,
            start_date,
            end_date
        }
    )
    return response;
}



export async function get_data_to_store(type,branch_id)
{
    const params = [
        {name:'type',value:type},
        {name:'branch_id',value:branch_id}
    ];

    let response = await POST(AdminRoutes.Orders.GetDataToStore,params);
    return response;
}

/**
 * @param branch_id
 * @param type [inbound/outbound]
 * @param entity_id
 * @param currency
 * @param date
 * @param number
 * @param payment_type
 * @param amount
 * @param ff_amount
 * @param notes
 * @param details
 */
export async function create_order(branch_id,type,entity_id,currency,date_production,date,number,payment_type,
    amount,ff_amount,notes,details,payments=[])
{
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'type',value:type},
        {name:'entity_id',value:entity_id},
        {name:'currency',value:currency},
        {name:'date_production',value:date_production},
        {name:'date',value:date},
        {name:'number',value:number},
        {name:'payment_type',value:payment_type},
        {name:'amount',value:amount},
        {name:'ff_amount',value:ff_amount},
        {name:'notes',value:notes},
        {name:'details',value:JSON.stringify(details)},
        {name:'payments',value:JSON.stringify(payments)}
    ];

    let response = await POST(AdminRoutes.Orders.create,params);
    return response;
}

export async function getOrder(branch_id,type,orderID)
{
    const params = [
        {name:'type',value:type},
        {name:'branch_id',value:branch_id},
        {name:'orderID',value:orderID}
    ];

    let response = await POST(AdminRoutes.Orders.GetOrder,params);
    return response;
}



export async function update_order(id,branch_id,type,entity_id,currency,date_production,date,number,payment_type,
    amount,ff_amount,notes,details,payments=[])
{
    const params = [
        {name:'id',value:id},
        {name:'branch_id',value:branch_id},
        {name:'type',value:type},
        {name:'entity_id',value:entity_id},
        {name:'currency',value:currency},
        {name:'date_production',value:date_production},
        {name:'date',value:date},
        {name:'number',value:number},
        {name:'payment_type',value:payment_type},
        {name:'amount',value:amount},
        {name:'ff_amount',value:ff_amount},
        {name:'notes',value:notes},
        {name:'details',value:JSON.stringify(details)},
        {name:'payments',value:JSON.stringify(payments)}
    ];

    let response = await POST(AdminRoutes.Orders.update,params);
    return response;
}