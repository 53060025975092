import { useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function EntitiesDataTable(props)
{
    const { type } = useParams();
    const [entities,setEntities] = useState(props.entities);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const columns = [
        {
            name:'id',
            label:<Typography>ID</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'name',
            label:<Typography>Nombre</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'tax_id',
            label:<Typography>Documento</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'phone',
            label:<Typography>Teléfono</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'email',
            label:<Typography>Email</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Actualizar'>
                                <IconButton onClick={()=>redirect(`/entities/update/${entities[dataIndex].id}`)}>
                                    <FontAwesomeIcon icon={faPencil}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title={<Typography>Listado de Entidades</Typography>}
            data={entities}
            columns={columns}
            options={options}
        />
    )
}