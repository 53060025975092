import { Button, Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get_branches } from "../../controllers/admin/BranchController";
import BranchContext from "../../navigation/context/branchContext";

export default function Home()
{
    const [branches,setBranches] = useState([]);
    const {branch_id} = useParams();

    const { selectedBranch, setSelectedBranch, clearSelectedBranch } = useContext(BranchContext);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        clearSelectedBranch();
        handleGetBranch();
    },[]);

    const handleGetBranch = async () => {
        let response = await get_branches(branch_id);
        if(response.success === true)
        {
            setBranches(response.data.branches);
        }
        console.log(response)
    }

    const handleBranchSelection = (_branch) => {
        setSelectedBranch(_branch);
        redirect('/branch/'+_branch.name)
    }

    
    return(
        <Container>
            <Grid container spacing={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Grid item lg={12} xl={12}>
                    <Typography variant="h4" textAlign={'center'}>
                        Selecciona una sucursal
                    </Typography>
                </Grid>
                {
                    branches.length !== 0 && 
                    branches.map(item =>
                        <Grid lg={3} xl={3} mt={5} width={'100%'}>
                            <Button fullWidth variant="outlined" onClick={()=>handleBranchSelection(item)}>
                                <Typography variant="h6">{item.name}</Typography>
                            </Button>
                        </Grid>
                    )
                }

                {selectedBranch && <p>Rama seleccionada: {selectedBranch.name}</p>}
                
            </Grid>
        </Container>
    )
}