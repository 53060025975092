import { useContext, useEffect, useReducer, useState } from "react";
import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Button, Container, Divider, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { getProductDetailsById } from '../../../controllers/admin/ProductsController';
import ProductDetailsDataTable from "./detailsDataTable";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';



export default  function ProductDetails()
{
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const [isLoading,setIsLoading] = useState(false);
    const [product,setProduct] = useState([]);
    const [details,setDetails] = useState([]);

    const [startDate,setStartDate] = useState(moment().startOf('month'));
    const [endDate,setEndDate] = useState(moment().add(1, 'month').startOf('month'));

    const [statistics,setStatistics] = useState([]);
    const { id } = useParams();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetProduct(_startDate,_endDate);
        handleCurrentMonth();
    },[]);


    const handleGetProduct = async (_startDate,_endDate) => {
        setIsLoading(true);
        const currentMonth = handleGetCurrentMonth();
        const nextMonth = handleGetNextMonth();
        
        let response = await getProductDetailsById(id,_startDate,_endDate);
        if(response.success === true)
        {
            setProduct(response.data.product);
            setDetails(response.data.details);
            setStatistics(response.data.statistics);
        }
        setIsLoading(false);
    }

    const handleSetInitialDates = () => {
        setStartDate(moment().startOf('month'));
        setEndDate(moment().add(1, 'month').startOf('month'));

        console.log('Fecha de inicio:', startDate.format('YYYY-MM-DD'));
        console.log('Fecha de fin:', endDate.format('YYYY-MM-DD'));
    }

    const handleCurrentMonth = () => {
        // Obtener la fecha actual
        const now = moment();

        // Obtener el número del mes actual (1-12)
        const currentMonth = now.month() + 1; // Sumamos 1 porque los meses en JavaScript comienzan en 0

        // Obtener el número del mes siguiente
        const nextMonth = now.add(1, 'month').month() + 1;

        console.log('Mes actual:', currentMonth);
        console.log('Mes siguiente:', nextMonth);
    }

    const handleGetCurrentMonth = () => {
        const now = moment();
        const startDate = moment().startOf('month');
        console.log('startDate ===> ',startDate);

        const endDate = moment().add(1, 'month').startOf('month');
        // Obtener el número del mes actual (1-12)
        const currentMonth = now.month() + 1; // Sumamos 1 porque los meses en JavaScript comienzan en 0
        return currentMonth;
    }

    const handleGetNextMonth = () => {
        const now = moment();
        const nextMonth = now.add(1, 'month').month() + 1;
        return nextMonth;
    }

    const handleSearch = async () => {
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetProduct(_startDate,_endDate);
    }



    return(
        <Container maxWidth="xl">
            <CustomBreadcrumbs returnTo={`/products`} crumbs={[{name:`productos`}]}/>
            <SectionTitle title={`Detalles de ${product.name}`}/>

            <Grid container spacing={3} mb={3} justifyContent={'center'}>
                <Grid item lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha inicial"
                                        //minDate={moment.now()}
                                        
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha final"
                                        //minDate={moment.now()}
                                        
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} xl={2}>
                            <Button
                                fullWidth 
                                sx={{height:'100%'}}
                                variant="contained"
                                onClick={()=>handleSearch()}
                            >
                                <FontAwesomeIcon size="2x" icon={faSearch}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                {
                    statistics.length !== 0
                    ?
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper elevation={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                                    <Grid container spacing={3}>
                                        {
                                            statistics.length !== 0 &&
                                            statistics.map(item=>{
                                                if(item.type === 'inbound')
                                                    return(
                                                        <>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Typography variant='h6'>Entrada</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.operaciones}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Operaciones</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.produccion}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Producción</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.recibido}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Recibido</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.unidad}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Medida</Typography>
                                                            </Grid>
                                                        </>
                                                    )
                                                else return null
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item lg={1} xl={1}>
                                    <Divider orientation="vertical" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                                    <Grid container spacing={3}>
                                        {
                                            statistics.length !== 0 &&
                                            statistics.map(item=>{
                                                if(item.type === 'outbound')
                                                    return(
                                                        <>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Typography variant='h6'>Salida</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.operaciones}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Operaciones</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.recibido}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Vendido</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                                                <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                                                                    {item.unidad}
                                                                </Typography>
                                                                <Typography textAlign={'center'}>Medida</Typography>
                                                            </Grid>
                                                        </>
                                                    )
                                                else return null
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    :
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>
                            No hay operaciones
                        </Typography>
                    </Grid>
                }
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        details.length !== 0 &&
                        <ProductDetailsDataTable details={details}/>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}